*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

:root {
  --black: #000;
  --white: #fff;
  --transWhite: rgb(134, 118, 118);
  --blue: #002366;
  --red: #ff0000;
  --transBlue: #002466b4;
  --linkGray: rgb(146, 141, 141);
  --cardTitle: #ff0000;
}
h5 {
  font-size: 1.2rem;
  position: absolute;
  bottom: 3rem;
  letter-spacing: 0.5rem;
  z-index: 50;
}
.App {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--black);
  color: var(--white);
  font-family: 'montserrat';
}


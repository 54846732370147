#contactUsWrapper {
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  row-gap: 15px;
  grid-template-areas:
    'header'
    'buildingImage'
    'location'
    'map'
    'hours';
}
#header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: center;
}
#buildingImage {
  grid-area: buildingImage;
}
#location {
  grid-area: location;
}
#map {
  grid-area: map;
}
#hours {
  grid-area: hours;
}
#contactUsWrapper h2 {
  font-size: 2.2rem;
}

#contactUsWrapper img {
  width: 100%;
  height: 30vh;
}

.contactMeContainer {
  width: 100%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  padding-left: 10%;
  justify-content: center;
}
#contactUsWrapper p {
  font-size: 1.2rem;
  line-height: 1.7rem;
}

#contactUsWrapper a {
  max-width: 10rem;
  height: 1.5rem;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 0.8rem;
  color: var(--white);
  z-index: 50;
  letter-spacing: 0.2rem;
  outline: 2px solid var(--white);
  padding: 0.5rem;
  border-radius: 5px;
  transition: 0.7s;
  margin-top: 2rem;
}
#contactUsWrapper a:hover {
  color: var(--linkGray);
  outline: 2px solid var(--linkGray);
}

.hoursContainer {
  width: 99%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 1.5rem;
}

@media (min-width: 768px) {
  #contactUsWrapper {
    margin-top: 5rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'header header'
      'buildingImage location'
      'hours map';
  }
  #contactUsWrapper img {
    width: 100%;
    height: auto;
  }
  #contactUsWrapper p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  #hours {
    padding-left: 10rem;
  }
}

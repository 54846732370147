#line-drawing-wrapper {
  height: 0;
  width: 0;
  position: absolute;
  top: 100vh;
  margin-left: 0rem;
  z-index: -100;
  opacity: 0;
  overflow: hidden;
}

@media (min-width: 768px) {
  #line-drawing-wrapper {
    height: 150vh;
    width: 40vw;
    z-index: 100;
    opacity: 1;
  }
}

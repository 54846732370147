#crewWrapper {
  width: 100vw;
  height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  /* overflow: hidden; */
}
.crewContentContainer {
  width: 100vw;
  max-height: 95vh;
  display: flex;
  overflow-x: scroll;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  bottom: 0;
}
#crewWrapper h2 {
  font-size: 2rem;
  position: absolute;
  top: 3rem;
  left: calc(50% - 9rem);
  letter-spacing: 0.5rem;
  z-index: 50;
}

@media (min-width: 768px) {
  #crewWrapper {
    height: 100vh;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
  }

  #crewWrapper h2 {
    top: 3rem;
    left: 4rem;
    letter-spacing: 0.5rem;
    z-index: 50;
  }
  .crewContentContainer {
    width: 55vw;
    height: 100vh;
    display: grid;
    overflow-y: unset;
    overflow-x: unset;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 3rem;
    top: .5rem;
  }
}

#navbarWrapper {
  width: 100vw;
  min-height: 10vh;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 99999999;
  background: linear-gradient(#131311b7 80%, transparent);
}

#navbarWrapper img {
  height: 2rem;
  width: auto;
  margin-left: 2rem;
}

#navbarWrapper ul {
  width: 0;
  height: 0;
  overflow: hidden;
}

@media (min-width: 768px) { 
  #navbarWrapper {
    position: fixed;
  }
  #navbarWrapper ul {
    height: 100%;
    width: 30%;
    margin-right: 2rem;
  
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    list-style: none;
  }
  #navbarWrapper ul a {
    text-decoration: none;
    font-size: 1rem;
    color:var(--white);
  }
  
  #navbarWrapper ul a:hover {
    transition: 0.7s;
    color:var(--linkGray);
  
  }
 }


#servicesWrapper {
  width: 100vw;
  height: 120vh;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
 
#serviceImage{
  width: auto;
  position: absolute;
  right: 0;
  height: 70%;
  opacity: 0;
}

#servicesWrapper a {
  text-decoration: none;
  font-size: 1.2rem;
  color: var(--white);
  z-index: 50;
  letter-spacing: 0.2rem;
  position: absolute;
  bottom: 2rem;
  right: calc(50% - 5rem);
  outline: 2px solid var(--white);
  padding: 1rem;
  border-radius: 10px;
  transition: 0.7s;
}
#servicesWrapper a:hover {
  color: var(--linkGray);
  outline: 2px solid var(--linkGray);
}

.grid-container {
  height: 100vh;
  width: 85vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  overflow: scroll;
}

.service-card-container {
  width: 100%;
  min-height: 6rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: .5rem;
}

.service-card-container img {
  width: 30%;
  height: 100%;
}

.service-description-container {
  width: 70%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.service-service {
  width: 75%;
  letter-spacing: 0.2rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  position: absolute;
  top: 0.5rem;
}

.grid-container p {
  color: white;
}

.service-description {
  min-height: 55%;
  font-size: 1rem;
  width: 100%;
  position: absolute;
  bottom: 0rem;
}
@media (min-width: 768px) {
  #servicesWrapper {
    height: 100vh;
    align-items: flex-end;
    justify-content: flex-start;
  }
  #serviceImage{
    opacity: 1;
  }
  .grid-container {
    height: 60%;
    width: 55%;
    margin-left: 5rem;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  #servicesWrapper a {
    bottom: 2rem;
    right: 15%;
  }
  .service-card-container {
    width: 98%;
    height: 98%;
    min-height: unset;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.crewCard {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 3rem 1.2rem;
  -webkit-box-reflect: below 1px linear-gradient(transparent, transparent, #0004);
}

.crewCard .box {
  position: relative;
  width: 15rem;
  height: 18rem;
  margin: 0 5px;
  border-radius: 20px;
  background: linear-gradient(var(--direction), var(--color) 25%, var(--white) 25%, var(--white) 50%, var(--color) 50%, var(--color) 75%, var(--white) 75%, var(--white) 100%);
  background-size: 7.5rem 7.5rem;
  filter: grayscale(0.7);
  animation: animatedCardBackground 1s linear infinite;
  animation-play-state: paused;
  transition: filter 1s;
  display: flex;
  justify-content: center;
  /* align-items: flex-end; */
}
/* Opposite way */
/* .crewCard .box:nth-child(2) {
  background: linear-gradient(135deg, var(--transcolor) 25%, var(--transWhite) 25%, var(--transWhite) 50%, var(--transcolor) 50%, var(--transcolor) 75%, var(--transWhite) 75%, var(--transWhite) 100%);
  background-size: 60px 60px;
} */
.crewCard .box:hover {
  animation-play-state: running;
  filter: grayscale(0);
}

@keyframes animatedCardBackground {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 7.5rem;
  }
}
.crewCard .box p {
  width: 4rem;
  height: 2rem;
  position: absolute;
  bottom: -1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: var(--white);
  text-shadow: 0.5px 0.5px rgb(44, 43, 43);
  font-weight: 600;
  transition: 0.7s;
  z-index: 50;
}

.crewCard:hover .box p {
  font-size: 1.5rem;
}
.crewCardPicture {
  width: 90%;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.7s;
  filter: grayscale(0);
}

.crewCard:hover .crewCardPicture {
  width: 105%;
}

.crewCard a {
  text-decoration: none;
  font-size: 1rem;
  color: var(--white);
  z-index: 50;
  letter-spacing: 0.2rem;
  position: absolute;
  margin-bottom: 1rem;
  outline: 2px solid var(--white);
  padding: 0.5rem;
  border-radius: 10px;
}

.crewCard a:hover {
  transition: 0.7s;
  color: var(--linkGray);
  outline: 2px solid var(--linkGray);
}

@media (min-width: 768px) {
  .crewCard {
    margin: unset;
  }
  .crewCard .box {
    width: 8rem;
    height: 10rem;
  }
}

#instaFeedWrapper {
  min-width: 100vw;
  min-height: 30vh;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background: red;
}
.instaPost {
  min-width: 10rem;
  min-height: 80%;
  background: white;
}

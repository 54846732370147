#heroWrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../../media/hero.png') no-repeat;
  background-size: cover;
  background-position: -22rem;
  overflow: hidden;
}

#heroWrapper a {
  text-decoration: none;
  font-size: 1.2rem;
  color: var(--white);
  z-index: 50;
  letter-spacing: 0.2rem;
  position: absolute;
  margin-top: 20rem;
  outline: 2px solid var(--white);
  padding: 1rem;
  border-radius: 10px;
  transition: 0.7s;
}
#heroWrapper a:hover {
  color: var(--linkGray);
  outline: 2px solid var(--linkGray);
}
.heroLogo {
  height: 12vh;
  width: auto;
  position: absolute;
  /* top: 50%; */
  top: calc(50% - 5rem);
}

.heroShadow {
  width: 100vw;
  height: 30vh;
  background: linear-gradient(to top, var(--black), transparent);
  position: absolute;
  bottom: -2vh;
}

/* // Large devices (desktops, 992px and up)  */
@media (min-width: 768px) { 
  #heroWrapper {
    background-position: unset;
  }
  .heroLogo {
    height: 20vh;
  }
 }
